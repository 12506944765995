<template>
  <!-- Hero -->
  <div class="bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-hero-1.svg') + ')'}">
    <div class="container space-2 space-top-3 text-center">
      <div class="w-md-85 w-lg-65 text-center mx-md-auto mb-4">
        <h1>API Portal Themes</h1>
        <p class="lead">Get started with FabriXAPI Portal themes to create a better developer experience for your API Portal quickly.</p>
      </div>
      <a class="btn btn-primary text-white transition-3d-hover" @click="scrollToList">Explore Now</a>
    </div>
  </div>
<!-- End Hero -->
</template>

<script>
export default {
  name: 'Hero',
  methods: {
    scrollToList() {
      var elmnt = document.getElementById('themes-list')
      elmnt.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
